/* eslint-disable react/destructuring-assignment */
import { environment } from '@doltech/core/lib/environment/environment';
import * as React from 'react';

const replaceDolVnToMainSite = (content) => {
  return content
    .replace('www.seo-int.dol.vn', 'tuhocielts.dolenglish.vn')
    .replace('seo-int.dol.vn', 'tuhocielts.dolenglish.vn')
    .replace('www.dol.vn', 'tuhocielts.dolenglish.vn')
    .replace('dol.vn', 'tuhocielts.dolenglish.vn');
};

const replaceImageCDNPath = (content) => {
  return content.replace('suijm9clouobj.vcdn.cloud', 'media.dolenglish.vn');
};

export const replaceLandingCanonicalUrl =
  ({ slug, isStructuralSEO }) =>
  (domNode: any) => {
    if (isStructuralSEO && domNode?.attribs?.rel === 'canonical') {
      return <link rel="canonical" href={`${environment.SEO_PREFIX}${slug}`} />;
    }
    if (domNode?.attribs?.rel === 'canonical') {
      return <link rel="canonical" href={replaceDolVnToMainSite(domNode.attribs.href)} />;
    }
    if (domNode?.attribs?.property === 'og:url') {
      return <meta property="og:url" content={replaceDolVnToMainSite(domNode?.attribs?.content)} />;
    }
    if (domNode?.attribs?.property === 'og:image') {
      return <meta property="og:image" content={replaceImageCDNPath(domNode?.attribs?.content)} />;
    }
    if (domNode?.attribs?.name === 'twitter:url') {
      return (
        <meta property="twitter:url" content={replaceDolVnToMainSite(domNode?.attribs?.content)} />
      );
    }
    if (domNode?.attribs?.name === 'twitter:image') {
      return <meta name="twitter:image" content={replaceImageCDNPath(domNode?.attribs?.content)} />;
    }
  };

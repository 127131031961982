import { css, keyframes } from 'styled-components';

export const shake = keyframes`
 0%,
  100% {
            transform: rotate(0deg);
            transform-origin: 50% 50%;
  }
  10% {
            transform: rotate(8deg);
  }
  20%,
  40%,
  60% {
            transform: rotate(-10deg);
  }
  30%,
  50%,
  70% {
            transform: rotate(10deg);
  }
  80% {
            transform: rotate(-8deg);
  }
  90% {
            transform: rotate(8deg);
  }
`;

export const shaking = css`
  .shaking {
    animation: ${shake} 1s ease-in-out 2s infinite;
  }
`;

export const horizontalShaking = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`;

import { SpokeLoading } from '@doltech/core/lib/auth/SpokeLoading';
import * as React from 'react';
import styled from 'styled-components';

const EmptyPage = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PageLoader = () => (
  <EmptyPage>
    <SpokeLoading />
  </EmptyPage>
);

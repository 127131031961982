export const figmaColors = {
  /** SEASHELL: #FFF2F0 */
  primary1: '#FFF2F0',

  /** SPANISH PINK: #EEBABA */
  primary2: '#EEBABA',

  /** NEW YORK PINK: #E18686 */
  primary3: '#E18686',

  /** FUZZY WUZZY: #D96464 */
  primary4: '#D96464',

  /** JASPER - BRAND: #D14242 */
  primary5: '#D14242',

  /** SWEET BROWN: #AC3737 */
  primary6: '#AC3737',

  /** BURNT UMBER: #862A2A */
  primary7: '#862A2A',

  /** CAPUT MORTUUM: #5F1E1E */
  primary8: '#5F1E1E',

  /** DARK SIENNA: #391212 */
  primary9: '#391212',

  /** ISABELLINE: #EBECED */
  neutral1: '#EBECED',

  /** QUICK SILVER: #9FA4A9 */
  neutral2: '#9FA4A9',

  /** TROLLEY GREY: #797F86 */
  neutral3: '#797F86',

  /** BLACK CORAL: #535B64 */
  neutral4: '#535B64',

  /** GUNMETAL: #2D3742 */
  neutral5: '#2D3742',

  /** LIGHT GUNMETAL: #252E37 */
  neutral6: '#252E37',

  /** DARK GUNMETAL: #1D232A */
  neutral7: '#1D232A',

  /** EERIE BLACK: #15191E */
  neutral8: '#15191E',

  /** SMOKY BLACK: #0D0F12 */
  neutral9: '#0D0F12',

  /** PURE WHITE: #FFFFFF */
  W2B1: '#FFFFFF',

  /** ANTI-FLASH WHITE: #F2F2F2 */
  W2B2: '#F2F2F2',

  /** GAINSBORO: #D9D9D9 */
  W2B3: '#D9D9D9',

  /** QUICK GREY: #A6A6A6 */
  W2B4: '#A6A6A6',

  /** GREY: #808080 */
  W2B5: '#808080',

  /** DARK LIVER: #4D4D4D */
  W2B6: '#4D4D4D',

  /** BLACK OLIVE: #404040 */
  W2B7: '#404040',

  /** JET: #333333 */
  W2B8: '#333333',

  /** JRAISIN BLACK: #262626 */
  W2B9: '#262626',

  /** DANGER1: #FEEDEC */
  danger1: '#FEEDEC',

  /** BUBBLE GUM: #FCCBC8 */
  danger2: '#FCCBC8',

  /** SALMON PINK: #FAA9A3 */
  danger3: '#FAA9A3',

  /** TEA ROSE: #F8877F */
  danger4: '#F8877F',

  /** PASTEL RED: #F6655A */
  danger5: '#F6655A',

  /** VERMILION: #F44336 */
  danger6: '#F44336',

  /** INTERNATIONAL ORANGE: #C8372D */
  danger7: '#C8372D',

  /** SIENNA: #86251E */
  danger8: '#86251E',

  /** PRUNE: #6E1F19 */
  danger9: '#6E1F19',

  /** COSMIC LATTE: #FFF6E7 */
  warning1: '#FFF6E7',

  /** PEACH: #FFE5B9 */
  warning2: '#FFE5B9',

  /** PEACH-ORANGE: #FFD38B */
  warning3: '#FFD38B',

  /** MUSTARD: #FFC25C */
  warning4: '#FFC25C',

  /** YELLOW ORANGE: #FFB12E */
  warning5: '#FFB12E',

  /** VIVID ORANGE PEEL: #FFA000 */
  warning6: '#FFA000',

  /** FULVOUS: #D18300 */
  warning7: '#D18300',

  /** BROWN (TRADITIONAL): #8C5800 */
  warning8: '#8C5800',

  /** CHOCOLATE (TRDL): #734900 */
  warning9: '#734900',

  /** ISABELGREEN: #ECF4ED */
  success1: '#ECF4ED',

  /** PASTEL GRAY: #C8E0C9 */
  success2: '#C8E0C9',

  /** CAMBRIDGE: #A4CBA6 */
  success3: '#A4CBA6',

  /** IGUANA GREEN: #80B782 */
  success4: '#80B782',

  /** SHINY SHAMROCK: #5CA25F */
  success5: '#5CA25F',

  /** MAY GREEN: #388E3C */
  success6: '#388E3C',

  /** MUGHAL GREEN: #2E7532 */
  success7: '#2E7532',

  /** CAL POLY POMONA GREEN: #1F4E21 */
  success8: '#1F4E21',

  /** PHTHALO GREEN: #1A401C */
  success9: '#1A401C',

  /** GLITTER: #E8F4FA */
  supportive1: '#E8F4FA',

  /** POWDER BLUE: #BADEF2 */
  supportive2: '#BADEF2',

  /** LIGHT CORNFLOWER BLUE: #8CC7E9 */
  supportive3: '#8CC7E9',

  /** BLUE JEANS: #5EB3E1 */
  supportive4: '#5EB3E1',

  /** BLEU DE FRANCE: #309DD9 */
  supportive5: '#309DD9',

  /** RICH ELECTRIC BLUE: #0288D1 */
  supportive6: '#0288D1',

  /** HONOLULU BLUE: #0270AC */
  supportive7: '#0270AC',

  /** DARK IMPERIAL BLUE: #013E5F */
  supportive8: '#013E5F',

  /** PRUSSIAN BLUE: #01334E */
  supportive9: '#01334E',

  bg1: '#F4F5F7',
  bg2: '#FBFBFB',
  bg3: '#f6f7fb',
  bg4: '#F3F3F3',
  bg5: '#f4f4f6',
  bg6: '#f9fafa',
  bg7: '#f4f8fc',
  bg8: '#E8ECEF',

  text1: '#505F79',
  text2: '#a5adba',
  text3: '#8A94A6',
  text5: '#454545',
  text6: '#8f92a1',
  text7: '#BEDFF1',

  gray1: '#7B8993',
  gray2: '#b3bac5',
  gray3: '#b0b7c3',
  gray4: '#4e5d78',
  gray100: '#FAFBFB',
  grayMedium: '#E1E4E8',

  shadow1: 'rgba(235, 236, 237, 0.25)',
  shadow2: 'rgba(121, 127, 134, 0.15)',

  border1: 'rgba(232, 236, 239, 0.3)',
  border2: 'rgba(232, 236, 239, 0.4)',
  border3: '#dfe1e6',
  border4: 'rgba(255, 255, 255, 0.01)',
  border5: '#e9e9ec',

  borderWarning1: 'rgba(209, 131, 0, 0.4)',

  logo1: '#E41E26',
  logo2: '#D96464',
  logo3: '#D22222',

  boxShadow: 'rgba(232, 236, 239, 0.25)',
  boxShadowHover: 'rgba(232, 236, 239, 0.35) 0 4px 4px 4px',

  blue1: '#2673DD',
  blue2: '#677CE0',
  blue3: '#337DD1',
  blue4: '#daf4fe',
  blue5: '#337dd1',
  blue6: '#2074bb',
  notificationTitle: '#0A1F44',
  notificationSubTitle: '#8A94A6',
  secondaryText: '#5E6C84',

  orange1: '#FF991F',
};

export const namedColors = {
  /** SEASHELL: #FFF2F0 */
  seashell: figmaColors.primary1,

  /** SPANISH PINK: #EEBABA */
  spanisPink: figmaColors.primary2,

  /** NEW YORK PINK: #E18686 */
  newYorkPink: figmaColors.primary3,

  /** FUZZY WUZZY: #D96464 */
  fuzzyWuzzy: figmaColors.primary4,

  /** JASPER - BRAND: #D14242 */
  jasperBrand: figmaColors.primary5,

  /** SWEET BROWN: #AC3737 */
  sweetBrown: figmaColors.primary6,

  /** BURNT UMBER: #862A2A */
  burntUmber: figmaColors.primary7,

  /** CAPUT MORTUUM: #5F1E1E */
  caputMortuum: figmaColors.primary8,

  /** DARK SIENNA: #391212 */
  darkSienna: figmaColors.primary9,

  /** ISABELLINE: #EBECED */
  isabelLine: figmaColors.neutral1,

  /** QUICK SILVER: #9FA4A9 */
  quickSilver: figmaColors.neutral2,

  /** TROLLEY GREY: #797F86 */
  trolleyGrey: figmaColors.neutral3,

  /** BLACK CORAL: #535B64 */
  blackCoral: figmaColors.neutral4,

  /** GUNMETAL: #2D3742 */
  gunmetal: figmaColors.neutral5,

  /** LIGHT GUNMETAL: #252E37 */
  lightGunmetal: figmaColors.neutral6,

  /** DARK GUNMETAL: #1D232A */
  darkGunmetal: figmaColors.neutral7,

  /** EERIE BLACK: #15191E */
  eerieBlack: figmaColors.neutral8,

  /** SMOKY BLACK: #0D0F12 */
  smokyBlack: figmaColors.neutral9,

  /** PURE WHITE: #FFFFFF */
  pureWhite: figmaColors.W2B1,

  /** ANTI-FLASH WHITE: #F2F2F2 */
  antiFlashWhite: figmaColors.W2B2,

  /** GAINSBORO: #D9D9D9 */
  gainsboro: figmaColors.W2B3,

  /** QUICK GREY: #A6A6A6 */
  quickGrey: figmaColors.W2B4,

  /** GREY: #808080 */
  grey: figmaColors.W2B5,

  /** DARK LIVER: #4D4D4D */
  darkLiver: figmaColors.W2B6,

  /** BLACK OLIVE: #404040 */
  blackOlive: figmaColors.W2B7,

  /** JET: #333333 */
  jet: figmaColors.W2B8,

  /** JRAISIN BLACK: #262626 */
  jraisinBlack: figmaColors.W2B9,

  /** DANGER1: #FEEDEC */
  danger1: figmaColors.danger1,

  /** BUBBLE GUM: #FCCBC8 */
  bubbleGum: figmaColors.danger2,

  /** SALMON PINK: #FAA9A3 */
  salmonPink: figmaColors.danger3,

  /** TEA ROSE: #F8877F */
  teaRose: figmaColors.danger4,

  /** PASTEL RED: #F6655A */
  pastelRed: figmaColors.danger5,

  /** VERMILION: #F44336 */
  vermilion: figmaColors.danger6,

  /** INTERNATIONAL ORANGE: #C8372D */
  internationalOrange: figmaColors.danger7,

  /** SIENNA: #86251E */
  sienna: figmaColors.danger8,

  /** PRUNE: #6E1F19 */
  prune: figmaColors.danger9,

  /** COSMIC LATTE: #FFF6E7 */
  cosmicLatte: figmaColors.warning1,

  /** PEACH: #FFE5B9 */
  peach: figmaColors.warning2,

  /** PEACH-ORANGE: #FFD38B */
  peachOrange: figmaColors.warning3,

  /** MUSTARD: #FFC25C */
  mustard: figmaColors.warning4,

  /** YELLOW ORANGE: #FFB12E */
  yellowOrange: figmaColors.warning5,

  /** VIVID ORANGE PEEL: #FFA000 */
  vividOrangePeel: figmaColors.warning6,

  /** FULVOUS: #D18300 */
  fulvous: figmaColors.warning7,

  /** BROWN (TRADITIONAL): #8C5800 */
  brownTraditional: figmaColors.warning8,

  /** CHOCOLATE (TRDL): #734900 */
  chocolateTraditional: figmaColors.warning9,

  /** ISABELGREEN: #ECF4ED */
  isabelGreen: figmaColors.success1,

  /** PASTEL GRAY: #C8E0C9 */
  pastelGray: figmaColors.success2,

  /** CAMBRIDGE: #A4CBA6 */
  cambridge: figmaColors.success3,

  /** IGUANA GREEN: #80B782 */
  iguanaGreen: figmaColors.success4,

  /** SHINY SHAMROCK: #5CA25F */
  shinyShamrock: figmaColors.success5,

  /** MAY GREEN: #388E3C */
  mayGreen: figmaColors.success6,

  /** MUGHAL GREEN: #2E7532 */
  mughalGreen: figmaColors.success7,

  /** CAL POLY POMONA GREEN: #1F4E21 */
  calPolyPomonaGreen: figmaColors.success8,

  /** PHTHALO GREEN: #1A401C */
  phthaloGreen: figmaColors.success9,

  /** GLITTER: #E8F4FA */
  glitter: figmaColors.supportive1,

  /** POWDER BLUE: #BADEF2 */
  powderBlue: figmaColors.supportive2,

  /** LIGHT CORNFLOWER BLUE: #8CC7E9 */
  lightCornflowerBlue: figmaColors.supportive3,

  /** BLUE JEANS: #5EB3E1 */
  blueJeans: figmaColors.supportive4,

  /** BLUE DE FRANCE: #309DD9 */
  blueDeFrance: figmaColors.supportive5,

  /** RICH ELECTRIC BLUE: #0288D1 */
  richElectricBlue: figmaColors.supportive6,

  /** HONOLULU BLUE: #0270AC */
  honoluluBlue: figmaColors.supportive7,

  /** DARK IMPERIAL BLUE: #013E5F */
  darkImperialBlue: figmaColors.supportive8,

  /** PRUSSIAN BLUE: #01334E */
  prussianBlue: figmaColors.supportive9,

  boxShadow: figmaColors.boxShadow,
  boxShadowHover: figmaColors.boxShadowHover,
};

import { memo } from 'react';
import * as React from 'react';

const LocalLightBublIcon = (props) => (
  <svg width="1em" height="1em" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15 15.19H9"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M8.54 14.896a5.99 5.99 0 0 1-2.465-5.858c.406-2.607 2.579-4.688 5.2-4.995A6.001 6.001 0 0 1 18 10a5.986 5.986 0 0 1-2.543 4.898 1.03 1.03 0 0 0-.457.835V18.5a2.5 2.5 0 0 1-2.5 2.5h-1A2.5 2.5 0 0 1 9 18.5v-2.763c0-.341-.182-.645-.46-.841Z"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m20 5 1.19-1.19M4 15l-1.19 1.19M5 4 3.81 2.81M20 15l1.19 1.19M21 10h1.69M1.31 10H3M9 18h5.87"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const LightBulbIcon = memo(LocalLightBublIcon);

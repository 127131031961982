export const getErrorInfo = (error) => {
  const { response, message, name, stack, request = {} } = error;
  const errorResult: any = {
    message,
    name,
    stack: JSON.stringify(stack, null, 2),
  };

  if (request) {
    errorResult.request = {
      path: request.path || '',
    };
  }

  if (response) {
    errorResult.response = {
      status: response.status,
      statusText: response.statusText,
      data: response.data,
    };
  }

  return errorResult;
};

export const printNetworkError = (ex, mode: 'full' | 'compact' = 'full') => {
  if (ex.response) {
    /*
     * The request was made and the server responded with a
     * status code that falls out of the range of 2xx
     */
    console.log('[Response Error]');
    console.log(`${ex.response.status} - ${ex.response.statusText}`);
    if (mode === 'full') {
      console.log('Headers', ex.response.headers);
    }
    console.log(ex.response.data);
    // eslint-disable-next-line no-underscore-dangle
    console.log(ex.request._header);
    console.log('');
  } else if (ex.request) {
    /*
     * The request was made but no response was received, `ex.request`
     * is an instance of XMLHttpRequest in the browser and an instance
     * of http.ClientRequest in Node.js
     */
    console.log('[Request Error]');
    console.log(ex.request);
    console.log('');
  }
  if (mode === 'full') {
    console.log('=======================');
    console.log('[Raw Error]');
    console.log(JSON.stringify(ex, null, 2));
    console.log('=======================');
    console.log('');
  }
};

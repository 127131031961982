import * as React from 'react';
import { getErrorInfo } from '@doltech/utils/lib/error';
import Head from 'next/head';
import { useBuildSeoMeta } from './buildSeoMeta';

interface SlugPageProps {
  urlInfo: any;
  data?: any;
  error?: any;
  slug?: any;
  structuralSeo?: any;
  schemaScriptString?: string;
  isStructuralSEO?: boolean;
  children?: any;
  hardCodeHeadTags?: string;
  parseLocalSeoMedataOption?: any;
  defaultLdSchema?: any;
}

const SlugPageError = (error) => (
  <div>
    <pre>Response error: {JSON.stringify(error, null, 2)}</pre>
  </div>
);

export const CommonSlugPage = (props: SlugPageProps) => {
  const {
    structuralSeo,
    schemaScriptString,
    isStructuralSEO,
    urlInfo,
    data,
    slug,
    children,
    parseLocalSeoMedataOption = undefined,
    hardCodeHeadTags = undefined,
    defaultLdSchema = undefined,
    ...rest
  } = props;
  const { seoMetadata, schemaScript, error } = useBuildSeoMeta({
    hardCodeHeadTags,
    structuralSeo,
    schemaScriptString,
    parseLocalSeoMedataOption,
  });
  const allProps = React.useMemo(
    () => ({
      seoData: urlInfo,
      ...data,
      ...rest,
    }),
    [data, rest, urlInfo]
  );
  if (error) {
    return <SlugPageError error={getErrorInfo(error)} />;
  }
  return (
    <>
      <Head>
        {defaultLdSchema}
        {seoMetadata}
        {schemaScript}
      </Head>
      {children({ allProps })}
    </>
  );
};

import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { Link } from '@doltech/ui/lib/figma/Link';
import { shadows } from '@doltech/ui/lib/figma/shadows';
import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import { shaking } from '@doltech/utils/lib/animations/shaking';
import * as React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.div<{ backgroundColor?: string }>`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 24px;
  padding: 12px;
  font-size: 24px;
  background-color: ${(props) => props.backgroundColor || colorsV2.yellow100};
`;

const Layout = styled.div`
  border-radius: 8px 0px 0px 8px;
  border: 1px solid ${colorsV2.gray20};
  background-color: ${colorsV2.white100};
  box-shadow: ${shadows.zIndex3};
  padding: 12px;

  width: 92px;
  height: 120px;
  transition: all 300ms;
  ${shaking}
`;

const Main = styled.div`
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  gap: 8px;
  place-items: center;

  .text {
    text-align: center;
  }
`;

interface FABProps {
  text: string;
  icon: React.ReactNode;
  backgroundColor: string;
  url: string;
}

export const FAB = (props: FABProps) => {
  const { url, backgroundColor, icon, text } = props;
  return (
    <Link href={url} target="_blank" rel="nofollow">
      <Layout>
        <Main>
          <IconWrapper className="shaking" backgroundColor={backgroundColor}>
            {icon}
          </IconWrapper>
          <ResponsiveTypography.Paragraph variant="medium/14-20" color="black100" className="text">
            {text}
          </ResponsiveTypography.Paragraph>
        </Main>
      </Layout>
    </Link>
  );
};

import { PageLoader } from '@doltech/ui/lib/figma/PageLoader/PageLoader';
import { PageTypeEnum } from '@doltech/utils/lib/constants';
import dynamic from 'next/dynamic';
import * as React from 'react';
import { CommonLayout } from './commons';
import { FABLandingHome } from '@doltech/modules/lib/landing-home/components/FABLandingHome';

const DoDictationTemplatePage = dynamic(
  () => import('../templates/DoDictationTemplatePage').then((mod) => mod.DoDictationTemplatePage),
  { loading: () => <CommonLayout /> }
);
const IeltsFullTestTemplatePage = dynamic(() => import('../templates/IeltsFullTestTemplatePage'), {
  loading: () => <CommonLayout />,
});

const BookSEOPageTemplate = dynamic(
  () => import('../templates/BookSEOPageTemplate').then((mod) => mod.BookSEOPageTemplate),
  { loading: () => <CommonLayout /> }
);
const LandingDictationTemplatePage = dynamic(
  () => import('../templates/LandingDictationTemplatePage'),
  { loading: () => <CommonLayout /> }
);

const LandingListeningPracticeTestTemplatePage = dynamic(
  () => import('../templates/LandingListeningPracticeTestTemplatePage'),
  { loading: () => <CommonLayout /> }
);
const LandingReadingPracticeTestTemplatePage = dynamic(
  () => import('../templates/LandingReadingPracticeTestTemplatePage'),
  { loading: () => <CommonLayout /> }
);
const ViewTestQuestionTemplatePage = dynamic(
  () => import('../templates/ViewTestQuestionTemplatePage'),
  { loading: () => <CommonLayout /> }
);
const ViewVocabTemplatePage = dynamic(() => import('../templates/ViewVocabTemplatePage'), {
  loading: () => <PageLoader />,
});

const DoTestTemplatePage = dynamic(() => import('../templates/DoTestTemplatePage'), {
  loading: () => <PageLoader />,
});

const ViewSolutionTemplatePage = dynamic(() => import('../templates/ViewSolutionTemplatePage'), {
  loading: () => <PageLoader />,
});
const HomePageTemplatePage = dynamic(() => import('../templates/HomePageTemplatePage'), {
  loading: () => <CommonLayout />,
});
const LandingDailyLearningTemplatePage = dynamic(
  () => import('../templates/LandingDailyLearningTemplatePage'),
  { loading: () => <CommonLayout /> }
);

const DailyLearningNewsTemplatePage = dynamic(
  () => import('../templates/DailyLearningNewsTemplatePage'),
  { loading: () => <CommonLayout /> }
);

const IeltsFullTestTemplatePageLayout = dynamic(
  () =>
    import('../templates/IeltsFullTestTemplatePage').then(
      (mod) => mod.IeltsFullTestTemplatePageLayout
    ),
  { loading: () => <CommonLayout /> }
);
const LandingDictationTemplatePageLayout = dynamic(
  () =>
    import('../templates/LandingDictationTemplatePage').then(
      (mod) => mod.DictationLandingTemplatePageLayout
    ),
  { loading: () => <CommonLayout /> }
);
const LandingPracticeTestTemplatePageLayout = dynamic(
  () =>
    import('../templates/LandingReadingPracticeTestTemplatePage').then(
      (mod) => mod.LandingPracticeTestTemplatePageLayout
    ),
  { loading: () => <CommonLayout /> }
);
const HomePageTemplatePageLayout = dynamic(
  () => import('../templates/HomePageTemplatePage').then((mod) => mod.HomePageTemplatePageLayout),
  { loading: () => <CommonLayout /> }
);
const LandingWritingSampleTemplatePage = dynamic(
  () => import('../templates/LandingWritingSampleTemplatePage'),
  { loading: () => <CommonLayout loading /> }
);

const LandingSpeakingSampleTemplatePage = dynamic(
  () => import('../templates/LandingSpeakingSampleTemplatePage'),
  { loading: () => <CommonLayout loading /> }
);

const LandingWritingSampleTaskTemplatePage = dynamic(
  () => import('../templates/LandingWritingSampleTaskTemplatePage'),
  { loading: () => <CommonLayout loading /> }
);

const LandingSpeakingSamplePartTemplatePage = dynamic(
  () => import('../templates/LandingSpeakingSamplePartTemplatePage'),
  { loading: () => <CommonLayout /> }
);

const WritingSampleDetailTemplatePageLayout = dynamic(
  () =>
    import('../templates/WritingSampleDetailTemplatePage').then(
      (mod) => mod.WritingSampleDetailTemplatePageLayout
    ),
  { loading: () => <CommonLayout /> }
);

const WritingSampleDetailTemplatePage = dynamic(
  () => import('../templates/WritingSampleDetailTemplatePage'),
  { loading: () => <CommonLayout /> }
);

const LandingSampleLayoutWithPromotion = dynamic(
  () =>
    import('@doltech/ui/lib/figma/Layout/Landing/PremadeLayout/LandingSampleLayoutWithPromotion'),
  {
    loading: () => <CommonLayout />,
  }
);

const LandingBlogDetailSampleLayout = dynamic(
  () => import('@doltech/ui/lib/figma/Layout/Landing/PremadeLayout/LandingBlogDetailSampleLayout'),
  {
    loading: () => <CommonLayout />,
  }
);

const SpeakingSampleDetailTemplatePage = dynamic(
  () => import('../templates/SpeakingSampleDetailTemplatePage'),
  {
    loading: () => <CommonLayout />,
  }
);

export const PageTemplate = ({ pageType, pageProps }) => {
  return (
    <>
      {pageType === PageTypeEnum.WRITING_SAMPLE_DETAIL && (
        <LandingBlogDetailSampleLayout pageProps={pageProps}>
          <WritingSampleDetailTemplatePageLayout {...pageProps}>
            <WritingSampleDetailTemplatePage {...pageProps} />
          </WritingSampleDetailTemplatePageLayout>
        </LandingBlogDetailSampleLayout>
      )}

      {pageType === PageTypeEnum.SPEAKING_SAMPLE_DETAIL && (
        <LandingBlogDetailSampleLayout pageProps={pageProps}>
          <WritingSampleDetailTemplatePageLayout {...pageProps}>
            <SpeakingSampleDetailTemplatePage {...pageProps} />
          </WritingSampleDetailTemplatePageLayout>
        </LandingBlogDetailSampleLayout>
      )}
      {pageType === PageTypeEnum.LANDING_WRITING_SAMPLE && (
        <LandingSampleLayoutWithPromotion pageType={pageType} pageProps={pageProps}>
          <LandingWritingSampleTemplatePage {...pageProps} />
        </LandingSampleLayoutWithPromotion>
      )}
      {pageType === PageTypeEnum.LANDING_SPEAKING_SAMPLE && (
        <LandingSampleLayoutWithPromotion pageType={pageType} pageProps={pageProps}>
          <LandingSpeakingSampleTemplatePage {...pageProps} />
        </LandingSampleLayoutWithPromotion>
      )}
      {pageType === PageTypeEnum.LANDING_WRITING_SAMPLE_T2A && (
        <LandingSampleLayoutWithPromotion pageType={pageType} pageProps={pageProps}>
          <FABLandingHome />
          <LandingWritingSampleTaskTemplatePage {...pageProps} />
        </LandingSampleLayoutWithPromotion>
      )}
      {pageType === PageTypeEnum.LANDING_WRITING_SAMPLE_T1 && (
        <LandingSampleLayoutWithPromotion pageType={pageType} pageProps={pageProps}>
          <FABLandingHome />
          <LandingWritingSampleTaskTemplatePage {...pageProps} />
        </LandingSampleLayoutWithPromotion>
      )}
      {pageType === PageTypeEnum.LANDING_WRITING_SAMPLE_T2G && (
        <LandingSampleLayoutWithPromotion pageType={pageType} pageProps={pageProps}>
          <FABLandingHome />
          <LandingWritingSampleTaskTemplatePage {...pageProps} />
        </LandingSampleLayoutWithPromotion>
      )}
      {pageType === PageTypeEnum.LANDING_SPEAKING_SAMPLE_P1 && (
        <LandingSampleLayoutWithPromotion pageType={pageType} pageProps={pageProps}>
          <FABLandingHome />
          <LandingSpeakingSamplePartTemplatePage {...pageProps} />
        </LandingSampleLayoutWithPromotion>
      )}

      {pageType === PageTypeEnum.LANDING_SPEAKING_SAMPLE_P2 && (
        <LandingSampleLayoutWithPromotion pageType={pageType} pageProps={pageProps}>
          <FABLandingHome />
          <LandingSpeakingSamplePartTemplatePage {...pageProps} />
        </LandingSampleLayoutWithPromotion>
      )}
      {pageType === PageTypeEnum.LANDING_SPEAKING_SAMPLE_P3 && (
        <LandingSampleLayoutWithPromotion pageType={pageType} pageProps={pageProps}>
          <FABLandingHome />
          <LandingSpeakingSamplePartTemplatePage {...pageProps} />
        </LandingSampleLayoutWithPromotion>
      )}
      {pageType === PageTypeEnum.LANDING_HOME && (
        <HomePageTemplatePageLayout {...pageProps}>
          <HomePageTemplatePage {...pageProps} />
        </HomePageTemplatePageLayout>
      )}

      {pageType === PageTypeEnum.VIEW_TEST_QUESTION && (
        <ViewTestQuestionTemplatePage {...pageProps} />
      )}

      {pageType === PageTypeEnum.VIEW_SOLUTION && <ViewSolutionTemplatePage {...pageProps} />}

      {pageType === PageTypeEnum.VIEW_VOCAB && <ViewVocabTemplatePage {...pageProps} />}

      {pageType === PageTypeEnum.LANDING_FULL_TEST && (
        <IeltsFullTestTemplatePageLayout {...pageProps}>
          <FABLandingHome />
          <IeltsFullTestTemplatePage {...pageProps} />
        </IeltsFullTestTemplatePageLayout>
      )}

      {pageType === PageTypeEnum.LANDING_READING_PRACTICE_TEST && (
        <LandingPracticeTestTemplatePageLayout {...pageProps}>
          <FABLandingHome />
          <LandingReadingPracticeTestTemplatePage {...pageProps} />
        </LandingPracticeTestTemplatePageLayout>
      )}
      {pageType === PageTypeEnum.LANDING_DICTATION && (
        <LandingDictationTemplatePageLayout {...pageProps}>
          <FABLandingHome />
          <LandingDictationTemplatePage {...pageProps} />
        </LandingDictationTemplatePageLayout>
      )}

      {pageType === PageTypeEnum.LANDING_LISTENING_PRACTICE_TEST && (
        <LandingPracticeTestTemplatePageLayout {...pageProps}>
          <FABLandingHome />
          <LandingListeningPracticeTestTemplatePage {...pageProps} />
        </LandingPracticeTestTemplatePageLayout>
      )}

      {pageType === PageTypeEnum.DO_DICTATION && <DoDictationTemplatePage {...pageProps} />}

      {pageType === PageTypeEnum.DO_TEST && <DoTestTemplatePage {...pageProps} />}

      {pageType === PageTypeEnum.LANDING_DAILY_LEARNING_NEWS && (
        <LandingDailyLearningTemplatePage {...pageProps} />
      )}

      {pageType === PageTypeEnum.DAILY_LEARNING_NEWS && (
        <DailyLearningNewsTemplatePage {...pageProps} />
      )}

      {pageType === PageTypeEnum.BOOK && <BookSEOPageTemplate {...pageProps} />}
    </>
  );
};

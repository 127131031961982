import * as React from 'react';
import parse from 'html-react-parser';
import { uniqBy } from 'lodash';

const isServerSide = typeof window === 'undefined';
const isClientSide = !isServerSide;

const allowedClientSideTags = ['title', 'link', 'meta', 'span', 'br'];

const mergeMetaTags = ({ hardCodeHeadTags = '', structuralSeo, parseLocalSeoMedataOption }) => {
  let hardCodeHeadTagsParsed = parse(hardCodeHeadTags, parseLocalSeoMedataOption) as any;
  const structuralSeoParsed = parse(structuralSeo, parseLocalSeoMedataOption) as any[];

  if (hardCodeHeadTagsParsed?.type) {
    hardCodeHeadTagsParsed = [hardCodeHeadTagsParsed];
  }

  let uniqueTags = [...structuralSeoParsed];
  if (hardCodeHeadTagsParsed) {
    uniqueTags = uniqBy(
      [...hardCodeHeadTagsParsed, ...structuralSeoParsed].filter((o) => o.type),
      (it) => {
        if (it?.type === 'meta') {
          return it?.props?.property || it?.props?.name;
        }
        return it?.type;
      }
    ).map((t, index) => {
      if (t?.type === 'meta' && t.props.property === 'og:image:alt') {
        return {
          ...t,
          props: {
            content: t.props.content,
            children: null,
          },
          key: index,
        };
      }
      return {
        ...t,
        key: index,
      };
    });
  }
  return uniqueTags
    .filter((o) => o?.type)
    .filter((o) => !['span', 'br'].includes(o?.type))
    .sort((a, b) => Number(a?.key) - Number(b?.key));
};

export const useBuildSeoMeta = ({
  hardCodeHeadTags,
  schemaScriptString,
  structuralSeo,
  schemaBlogPosting = undefined,
  parseLocalSeoMedataOption,
}) => {
  const { schemaBlogPostingScript, seoMetadata, schemaScript, error } = React.useMemo(() => {
    let localSeoMetadata = null;
    let localSchemaScript = null;
    let localError = null;
    let localSchemaBlogPostingScript = null;

    if (structuralSeo) {
      try {
        localSeoMetadata = mergeMetaTags({
          hardCodeHeadTags,
          structuralSeo,
          parseLocalSeoMedataOption,
        });
      } catch (ex) {
        localError = ex;
      }
    }

    if (isClientSide && localSeoMetadata) {
      localSeoMetadata = localSeoMetadata.filter((meta) =>
        allowedClientSideTags.includes(meta?.type)
      );
    }

    if (schemaScriptString) {
      try {
        localSchemaScript = parse(schemaScriptString, parseLocalSeoMedataOption);
      } catch (ex) {
        localError = ex;
      }
    }

    if (schemaBlogPosting) {
      try {
        localSchemaBlogPostingScript = parse(schemaBlogPosting, parseLocalSeoMedataOption);
      } catch (ex) {
        localError = ex;
      }
    }

    return {
      seoMetadata: localSeoMetadata,
      schemaScript: localSchemaScript,
      schemaBlogPostingScript: localSchemaBlogPostingScript,
      error: localError,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    seoMetadata,
    schemaScript,
    schemaBlogPostingScript,
    error,
  };
};

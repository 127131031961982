import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import * as React from 'react';
import styled from 'styled-components';

import { environment } from '@doltech/core/lib/environment/environment';
import { LightBulbIcon } from '../icons/LightBulbIcon';
import { ReviewIcon } from '../icons/ReviewIcon';
import { TeacherIcon } from '../icons/TeacherIcon';
import { FAB } from './FAB';
import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';

const Main = styled.div`
  > * {
    &:not(:last-child) {
      display: block;
      margin-bottom: 16px;
    }
  }

  display: none;
  ${fromScreen(1366)} {
    display: initial;
    position: fixed;
    top: 154px;
    right: 0;
    z-index: 10;
  }
`;

export const FABLandingHome = () => {
  return (
    <Main>
      <FAB
        text="Điểm & Review"
        icon={<ReviewIcon />}
        backgroundColor={colorsV2.primary100}
        url={`${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/hall-of-fame`}
      />
      <FAB
        text="DOL SuperLMS"
        icon={<TeacherIcon />}
        backgroundColor={colorsV2.green100}
        url={environment.REACT_APP_DOL_SUPER_LMS_HOST_NAME}
      />
      <FAB
        text="Linear-thinking"
        icon={<LightBulbIcon />}
        backgroundColor={colorsV2.yellow100}
        url={`${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/gioi-thieu-linearthinking`}
      />
    </Main>
  );
};
